import React, { createRef } from 'react';
import Layout from '../components/layout/layout';
import Navigation from '../components/navigation/navigation';
import About from '../components/about/about';
import Footer from '../components/footer/footer';

const TainaPage = () => {
  const {taina} = createRef;
  const finnArray = [
      {
        key: 1,
        content: `Hei ja tervetuloa`,
      },
      {
        key: 2,
        content: `Minun nimeni on Taina ja olen kotoisin
        Itä Helsingistä.`,
      },
      {
        key: 3,
        content: `Perheeseeni kuuluu lasten lisäksi kissa ja
        3 koiraa jotka vievät aikaani. Lisäksi
        harrastukseni käsityöt ja kilpatanssi
        saavat ajan kulumaan.`,
      },
      {
        key: 4,
        content: `Valmistuin hierojaksi muutama vuosi
        sitten Itä Helsingin hierojakoulusta.
        Opiskelu oli mielenkiintoista ja
        intensiivistä.`,
      },
      {
        key: 5,
        content: `Asiakkaiden hyvinvointi on lähellä
        sydäntä koska toisista huolehtiminen on
        aina ollut se ”minun juttuni”. Mikään ei
        ole sen parempaa kuin nähdä miten voin
        auttaa toista ihmistä.`,
      },
      {
        key: 6,
        content: `Puhun äidinkielenäni Suomea ja lisäksi
        sujuvaa Englantia.`,
      },
      {
        key: 7,
        content: `Toivottavasti näen juuri sinut hieronnan
        merkeissä.`,
      },
      {
        key: 8,
        content: `Ystävällisin terveisin`,
      },
      {
        key: 9,
        content: `- Hierojasi Taina -`,
      }
  ];

  return (
      <Layout>
        <title>Taina</title>
        <div className='site-wrapper'>
          <Navigation
            homeRoute={`/`}
            langLink={`EN`}
            langTitle={'Englanninkielinen sivu'}
            language={`/taina-en`}
            home={`Kotisivu`}
          />
          <main className="new-page">
            <About 
              sectionTitle={`Taina`} 
              ref={taina}
              aboutTextArray={finnArray}
            />
          </main>
          <Footer followMe={`Seuraa minua`} contactInfo={`Yhteystiedot`}/>
        </div>
     </Layout>
  )
}

export default TainaPage;